// {Assays.tsx}

import { Box } from '@mui/material';
import React from 'react';
import { ManageAssays as ManageAssaysContent} from 'quil';
import { useNavigate } from 'react-router-dom';
// import useStyles from './styles';
// import TEST_ID from './constants';

export interface AssaysProps {
  // Props
}

export default function Assays() {
  const navigate = useNavigate();
  const handleOpen = (protocolId: string) => {
    navigate('editor', {state: {protocolId}});
  };

  return (
    <Box width="100%" height="100%">
      <ManageAssaysContent onOpen={handleOpen}/>
    </Box>
  );
}
